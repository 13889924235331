import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import {AppLanguageEnum} from "../../app/system/enums/app-language.enum";
import {TRANSLATIONS} from "./translations/translations.const";
import {AuthService} from "../../app/system/auth/auth.service";
import {Injectable} from "@angular/core";


@Injectable()
export class StaticTranslateLoader implements TranslateLoader {

    constructor(private authService:AuthService) {
    }

    getTranslation(): Observable<any> {
        const user = this.authService.user.value;
        const selectedLang = user?.language.toLowerCase() || AppLanguageEnum.GB; // Reihenfolge: User > lang > Fallback
        console.log('User:', user, 'Selected:', selectedLang);
        return of(TRANSLATIONS[selectedLang]);
    }
}