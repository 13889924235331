import {Component, OnDestroy} from "@angular/core";
import {PrimeIcons} from "primeng/api";
import {NavigationEnd, Router} from "@angular/router";
import {getCurrentRoute} from "../../../../functions/get-current-route.function";
import {NavigationService} from "../../../../services/navigation.service";
import {Subscription} from "rxjs";
import {AuthService} from "../../../../auth/auth.service";

@Component({
  selector: 'app-header-switch-enterprise-button',
  templateUrl: './switch-enterprise-button.component.html',
  styleUrls: ['./switch-enterprise-button.component.scss']
})
export class SwitchEnterpriseButtonComponent implements OnDestroy {

  private routerSub: Subscription;
  activeEnterpriseId: string;
  activeEnterpriseName: string;

  constructor(
      private navigationService:NavigationService,
      private router:Router,
      private authService:AuthService,
  ) {

    this.initEnterpriseData()

    this.routerSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.initEnterpriseData()
      }
    });

  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }

  async navigate(route: string[]) {
    await this.navigationService.navigate(route, false);
  }

  initEnterpriseData() {

    const activeEnterpriseId = this.router.url.split('/')[1];

    const validEnterprise = this.authService.user.value.enterprises.find(enterprise => enterprise.id === activeEnterpriseId);

    if(validEnterprise) {
      const activeEnterpriseName =  validEnterprise.name;

      const activeEnterpriseNameParts = activeEnterpriseName.split(' ')
      this.activeEnterpriseName = '';
      if (activeEnterpriseNameParts.length > 1) {
        for (const part of activeEnterpriseNameParts) {
          this.activeEnterpriseName += part.charAt(0).toUpperCase();
        }
      }
    }
  }

}