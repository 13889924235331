import {TranslateService} from "@ngx-translate/core";

export class MenuBasicModel {


    menuItems = [];

    constructor(

        private translate: TranslateService,

        private currentRoute?: string,


    ) {


        this.menuItems.push([
            {
            label: 'Office',
            icon: 'pi pi-print',
            styleClass: 'primeNavItem',
            items: [
                {
                    label: this.translate.instant('Office'),
                    icon: 'mi mi-computer',
                    routerLink: [this.currentRoute, 'office'],
                    routerLinkActiveOptions: {exact: true}
                },
                {
                    label: this.translate.instant('Properties'),
                    icon: 'mi mi-apartment',
                    routerLink: [this.currentRoute, 'properties'],
                    routerLinkActiveOptions: {exact: true}
                },
                {
                    label: this.translate.instant('Management'),
                    icon: 'mi mi-room-preferences',
                    routerLink: [this.currentRoute, 'management'],
                    routerLinkActiveOptions: {exact: true}
                },
                {
                    label: this.translate.instant('Rentals'),
                    icon: 'mi mi-family-restroom',
                    routerLink: [this.currentRoute, 'rentals'],
                    routerLinkActiveOptions: {exact: true},
                },
/*                {
                    label: this.translate.instant('Sales'),
                    icon: 'mi mi-real-estate-agent',
                    routerLink: [this.currentRoute, 'sales'],
                    routerLinkActiveOptions: {exact: true},
                },*/
            ]
        }])

        this.translateMenuItems();

    }
    translateMenuItems() {
        this.menuItems[0][0].label = this.translate.instant(this.menuItems[0][0].label);
        this.menuItems[0][0].items[0].label = this.translate.instant('Office')
        this.menuItems[0][0].items[1].label = this.translate.instant('Properties')
        this.menuItems[0][0].items[2].label = this.translate.instant('Management')
        this.menuItems[0][0].items[3].label = this.translate.instant('Rentals')
        // this.menuItems[0][0].items[4].label = this.translate.instant('Sales')
    }




    changeActiveItem(name: string, iconClass: string) {
        const newMenuItems = this.menuItems[0];
        newMenuItems[0].label = this.translate.instant(name);
        newMenuItems[0].icon = iconClass;
        this.menuItems[0] = newMenuItems;
    }




}




