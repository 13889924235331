import {Component, HostListener, OnDestroy, OnInit, Output} from "@angular/core";
import {MenuItem, PrimeIcons} from "primeng/api";
import {HeaderService} from "../../header.service";
import {Subscription} from "rxjs";
import {NavigationService} from "../../../../services/navigation.service";

@Component({
    selector: 'app-header-module-menu',
    templateUrl: './module-menu.component.html',
    styleUrls: ['./module-menu.component.scss']
})
export class ModuleMenuComponent implements OnDestroy {

    headerMenuSub: Subscription;
    moduleMenuIcon: string;
    moduleMenuItems: MenuItem[];
    collapsedMenuItems: MenuItem[];
    screenWidth: number = window.innerWidth;

    tabMenuItems: MenuItem[] = [
        {
            automationId: 1,
            icon: 'mi mi-computer',
            command: () => this.onTabChange(this.tabMenuItems[0])
        },
        {
            automationId: 2,
            icon: 'mi mi-apartment',
            command: () => this.onTabChange(this.tabMenuItems[1])
        },
        {
            automationId: 3,
            icon: 'mi mi-room-preferences',
            command: () => this.onTabChange(this.tabMenuItems[2])
        },
        {
            automationId: 4,
            icon: 'mi mi-family-restroom',
            command: () => this.onTabChange(this.tabMenuItems[3])
        },
    ]

    activeItem: MenuItem = this.tabMenuItems[0];

    constructor(
        private headerService: HeaderService,
        private navigationService: NavigationService,
    ) {
        this.mapMenuItems(this.headerService.headerMenuItems);
        this.headerMenuSub = this.headerService.headerMenuItemsUpdate.subscribe(headerMenuItems => {
            this.mapMenuItems(headerMenuItems);
        });
    }

    ngOnDestroy() {
        this.headerMenuSub.unsubscribe();
    }

    mapMenuItems(headerMenuItems: MenuItem[]) {
        this.moduleMenuIcon = headerMenuItems[0]?.icon;
        this.moduleMenuItems = headerMenuItems.splice(0, 1)[0].items;
        this.collapsedMenuItems = [...headerMenuItems];
    }

    onTabChange($event: any) {
        if ($event.automationId === 1) {
            this.navigationService.navigate(['office']);
        } else if ($event.automationId === 2) {
            this.navigationService.navigate(['properties']);
        } else if ($event.automationId === 3) {
            this.navigationService.navigate(['management']);
        } else if ($event.automationId === 4) {
            this.navigationService.navigate(['rentals']);
        }
    }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.screenWidth = window.innerWidth;
  }


}