import {Component} from "@angular/core";
import {PrimeIcons} from "primeng/api";
import {Router} from "@angular/router";
import {getCurrentRoute} from "../../../../functions/get-current-route.function";
import {NavigationService} from "../../../../services/navigation.service";

@Component({
  selector: 'app-header-switch-country-button',
  templateUrl: './switch-country-button.component.html',
  styleUrls: ['./switch-country-button.component.scss']
})
export class SwitchCountryButtonComponent {

  constructor(
  ) {
  }




}