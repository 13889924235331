import {
    AfterViewChecked,
    AfterViewInit,
    Component,
    HostListener,
    OnDestroy,
    OnInit,
} from '@angular/core';
import {Subscription} from "rxjs";
import {MenuItem} from 'primeng/api';
import {AuthService} from "../../auth/auth.service";
import {HeaderService} from "./header.service";
import {UserEnterprise} from "../../auth/user.model";


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit, OnInit, OnDestroy {

    private userSub: Subscription = new Subscription;
    isAuthenticated: boolean = false;
    userEnterprises: UserEnterprise[];
    activeEnterpriseId: string;
    savedMobileMenuItems: MenuItem[] = [];
    mobileMenuItems: MenuItem[] = [];
    screenWidth!: number;
    private routerSub: Subscription;


    constructor(
        private authService: AuthService,
        private headerService: HeaderService,
    ) {
    }


    ngOnInit() {

        this.userSub = this.authService.user.subscribe(user => {
            this.isAuthenticated = !!user;
            if (user?.enterprises) {
                this.userEnterprises = user.enterprises;
            }
        });


        this.headerService.headerMenuItemsUpdate.subscribe(headerMenuItems => {
            this.savedMobileMenuItems = Object.assign([], headerMenuItems)
            this.updateMobileMenuItems();
        });

    }


    ngAfterViewInit() {
        this.screenWidth = window.innerWidth;
        this.headerService.screenwidth = window.innerWidth;
        this.updateMobileMenuItems();
    }

    ngOnDestroy() {
        this.routerSub.unsubscribe();
        this.userSub.unsubscribe();
    }

    @HostListener('window:resize', ['$event'])
    onResize() {
        this.screenWidth = window.innerWidth;
        this.updateMobileMenuItems();
    }


    updateMobileMenuItems() {
        if(this.screenWidth <= 962) {
            this.mobileMenuItems = [...this.savedMobileMenuItems]
        } else {
            this.mobileMenuItems = [];
        }
    }

}
