<ng-container *ngIf="screenWidth < 962">
    <p-tabMenu class="header-mobile-tab-menu" [model]="tabMenuItems" [activeItem]="activeItem">
    </p-tabMenu>
</ng-container>


<div class="flex flex-no-wrap" *ngIf="screenWidth > 962">

    <div class="header-menu-button module-menu-button">
        <p-tieredMenu [showTransitionOptions]="'60ms'" [hideTransitionOptions]="'60ms'" #moduleMenu
                      [popup]="true"
                      [model]="moduleMenuItems"></p-tieredMenu>
        <button type="button" pButton [icon]="moduleMenuIcon"
                (click)="moduleMenu.toggle($event)"
                class="p-button p-button-rounded"></button>
    </div>
    <div class="header-menu-button">
        <p-tieredMenu [showTransitionOptions]="'60ms'" [hideTransitionOptions]="'60ms'" #collapsedMenu
                      [popup]="true"
                      [model]="collapsedMenuItems"></p-tieredMenu>
        <button type="button" pButton label="" icon="mi mi-apps"
                (click)="collapsedMenu.toggle($event)"
                class="p-button p-button-rounded"></button>
    </div>

</div>


