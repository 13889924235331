

export const TRANSLATIONS = {
    "de":{

        settings: {
            user_settings: {
                "PERSONAL_INFORMATION" : "Persönliche Informationen",
            }
        },


        "VENDOR": "Lieferant",
        "TENANT": "Mieter",
        "EMPLOYEE": "Mitarbeiter",
        "CONTACT": "Kontakt",

        "LETTER": "Brief",

        "Mailbox-Messages": "Postfach-Nachrichten",
        "Import-Date": "Importdatum",
        "Archived-At": "Archiviert am",
        "Sender-Relation": "Absender-Beziehung",
        "Sender": "Absender",
        "Letter-Date": "Briefdatum",
        "Type": "Typ",
        "Subject": "Betreff",
        "Content": "Inhalt",
        "Archived?": "Archiviert?",

        "Please select": "Bitte auswählen",

        "building": "Gebäude",
        "buildings": "Gebäude",
        "COMMERCIAL": "Gewerblich",
        "RESIDENTIAL": "Wohnen",

        "Simple Self Disclosures": "Einfache Selbstauskünfte",


        "Office": "Büro",
        "Contacts": "Kontakte",
        "Mailbox": "Postfach",
        "Writings": "Schriftverkehr",
        "All Writings": "Gesamter Schriftverkehr",
        "Delivery Attempts": "Zustellversuche",
        "Accounting": "Buchhaltung",
        "Documents": "Dokumente",
        "Invoices": "Rechnungen",
        "Other Documents": "Andere Dokumente",
        "Financial Statements": "Finanzberichte",
        "Payrolls": "Lohnabrechnungen",
        "All Payrolls": "Alle Lohnabrechnungen",
        "Employee-Payrolls": "Mitarbeiter-Lohnabrechnungen",
        "Payroll-Documents": "Lohnabrechnungs-Dokumente",
        "Banking": "Bankwesen",
        "Bank-Accounts": "Bankkonten",
        "Bank-Transactions": "Banktransaktionen", "Bank-Transaction": "Banktransaktion",
        "Payments": "Zahlungen",
        "Payment-Claims": "Zahlungsforderungen",
        "All Payment-Claims": "Alle Zahlungsforderungen",
        "Dunnings": "Mahnungen",
        "Lawsuits": "Klagen",
        "Vendors": "Lieferanten",
        "Employees": "Mitarbeiter",
        "Human Resources": "Personalwesen",

        "Properties": "Immobilien",
        "Plots": "Grundstücke",
        "Buildings": "Gebäude",
        "Building-Complexes": "Gebäudekomplexe",
        "Units": "Einheiten",
        "Floor Plans": "Grundrisse",
        "Meters": "Zähler",
        "Rooms": "Räume",

        "Management": "Verwaltung",
        "Utility Bills": "Nebenkostenabrechnungen",
        "All Utility Bills": "Alle Nebenkostenabrechnungen",
        "Unit-Billings": "Einheits-Abrechnungen",
        "Cost-list": "Kostenliste",
        "Maintenance": "Wartung",
        "Repairs": "Reparaturen",
        "House-Rules": "Hausordnung",



        "CHIMNEY_SWEEPER": "Schornsteinfeger",
        "AREA_M2": "Fläche (m²)",
        "CONSUMPTION_MWH": "Verbrauch (MWh)",
        "CONSUMPTION_KWH": "Verbrauch (kWh)",
        "CONSUMPTION_M3": "Verbrauch (m³)",
        "PERSON_MONTHS": "Personenmonate",

        "Rentals": "Vermietungen",
        "Tenants": "Mieter",
        "Rental Status": "Mietstatus",
        "RentalStatus": "Mietstatus",
        "Contracts": "Verträge",
        "All Contracts": "Alle Verträge",
        "Contract Supplements": "Vertragszusätze",
        "Residents": "Bewohner",
        "All Residents": "Alle Bewohner",
        "Pets": "Haustiere",
        "Rent": "Miete",
        "Changes": "Änderungen",
        "Increases": "Erhöhungen",
        "Reductions": "Minderungen",
        "Exposes": "Exposés",
        "All Exposes": "Alle Exposés",
        "Listings": "Angebote",
        "All Listings": "Alle Angebote",
        "Publications": "Veröffentlichungen",
        "Move-Outs": "Auszüge",
        "Contract Terminations": "Kündigungen",
        "Termination Confirmations": "Kündigungsbestätigungen",
        "Handovers": "Übergaben",
        "Deposit Invoices": "Kautionsabrechnungen",
        "Archive": "Archiv",
        "Move-Ins": "Einzüge",
        "All Requests": "Alle Anfragen",
        "NEW_REQUEST": "Neue Anfrage",
        "SIMPLE_SELF_DISCLOSURE": "Einfache Selbstauskunft",
        "VIEWING_APPOINTMENT": "Besichtigungstermin",
        "SELF_DISCLOSURE": "Selbstauskunft",
        "SOLVENCY_PROOF": "Bonitätsnachweis",
        "RENTAL_CONTRACT_CREATION": "Vertragserstellung",
        "DECLINE": "Absage",
        "ADD_TO_WAITING_LIST": "Auf Warteliste setzen",

        "New Requests": "Neue Anfragen",
        "Simple Self-Disclosures": "Einfache Selbstauskünfte",
        "Viewing Appointments": "Besichtigungstermine",
        "Detailed Self-Disclosures": "Detaillierte Selbstauskünfte",
        "Solvency Proofs": "Bonitätsnachweise",
        "Contract Creations": "Vertragserstellungen",
        "All Handovers": "Alle Übergaben",
        "Handover Appointments": "Übergabetermine",
        "Welcome-Letters": "Willkommensschreiben",
        "Waitlist": "Warteliste",
        "Declines": "Absagen",


        "Sales": "Verkauf",


        "EMPTY": "Leerstand",
        "SELF_USAGE": "Eigennutzung",
        "RENT_AS_SHARED_UNIT": "Vermietung als geteilte Einheit",
        "UNDER_CONSTRUCTION": "Im Bau",


        "NEW": "Neu",
        "WAITING": "Wartend",
        "CONFIRMED": "Bestätigt",
        "LANDLORD_CAUSED": "Vermieterseits"





    },
    "gb": {
        "building": "En",



        "RETAIL_RENT": "Retail",
        "PERSONAL_INFORMATION": "Personal Information"
    }

};