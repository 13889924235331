<ng-container *ngIf="isAuthenticated">

    <div class="header flex flex-nowrap">
        <div class="flex-grow-1">
            <p-menubar [autoDisplay]="true" [model]="mobileMenuItems" #menubar>

                <ng-template pTemplate="start">
                    <app-header-module-menu></app-header-module-menu>
                </ng-template>

                <ng-template pTemplate="end">
                    <div class="flex" #menubarEnd>
                        <app-header-switch-enterprise-button></app-header-switch-enterprise-button>
                        <app-header-switch-country-button></app-header-switch-country-button>
                        <app-header-tasks-button></app-header-tasks-button>
                        <app-header-appointments-button></app-header-appointments-button>
                        <app-header-profile-menu></app-header-profile-menu>
                    </div>
                </ng-template>
            </p-menubar>
        </div>
    </div>

</ng-container>
