import {NgModule} from "@angular/core";
import {ProfileMenuComponent} from "./sub-components/6-profile-menu/profile-menu.component";
import {HeaderComponent} from "./header.component";
import {MenubarModule} from "primeng/menubar";
import {TieredMenuModule} from "primeng/tieredmenu";
import {ButtonDirective} from "primeng/button";
import {MenuModule} from "primeng/menu";
import {BadgeModule} from "primeng/badge";
import {TabMenuModule} from "primeng/tabmenu";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {AppointmentsButtonComponent} from "./sub-components/5-appointments-button/appointments-button.component";
import {TasksButtonComponent} from "./sub-components/4-tasks-button/tasks-button.component";
import {SwitchCountryButtonComponent} from "./sub-components/3-switch-country-button/switch-country-button.component";
import {
    SwitchEnterpriseButtonComponent
} from "./sub-components/2-switch-enterprise-button/switch-enterprise-button.component";
import {ModuleMenuComponent} from "./sub-components/1-module-menu/module-menu.component";


@NgModule({
    imports: [
        MenubarModule,
        TieredMenuModule,
        ButtonDirective,
        MenuModule,
        BadgeModule,
        TabMenuModule,
        NgIf,
        NgOptimizedImage
    ],
    declarations: [
        HeaderComponent,
        ModuleMenuComponent,
        SwitchEnterpriseButtonComponent,
        SwitchCountryButtonComponent,
        TasksButtonComponent,
        AppointmentsButtonComponent,
        ProfileMenuComponent,
    ],
    providers: [],
    exports: [
        HeaderComponent
    ]
})
export class HeaderModule {
}