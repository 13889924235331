import {TranslateService} from "@ngx-translate/core";
import {RentRequestStatus} from "../../../../../../4-rentals/move-ins/01-requests/enums/rent-request-status.enum";
import {Router} from "@angular/router";
import {MenuItem} from "primeng/api";
import {
    HandoverProtocolType
} from "../../../../../../4-rentals/move-outs/04-handovers/02-move-out-handover-protocols/enums/handover-protocol-type.enum";

export class MenuRentalsModel {

    menuItems: MenuItem;

    constructor(
        private translate: TranslateService,
        private currentRoute?: string,
    ) {
        this.menuItems = [
            {
                label: this.translate.instant('Tenants'),
                icon: 'mi mi-diversity-1 ',
                routerLinkActiveOptions: {exact: true},
                items: [
                    {
                        label: this.translate.instant('Rental Status'),
                        icon: 'mi mi-apartment',
                        routerLink: [this.currentRoute, 'rentals', 'list'],
                    },
                    {
                        separator: true,
                    },
                    {
                        label: this.translate.instant('Contracts'),
                        icon: 'mi mi-description',
                        items: [
                            {
                                label: this.translate.instant('All Contracts'),
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'contracts'],
                            },
                            {
                                separator: true,
                            },
                            {
                                label: this.translate.instant('Contract Supplements'),
                                icon: 'mi mi-post-add',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'contract-supplements'],
                            },
                        ]
                    },
                    {
                        label: this.translate.instant('Residents'),
                        icon: 'mi mi-family-restroom',
                        items: [
                            {
                                label: this.translate.instant('All Residents'),
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'residents'],
                            },
                            {
                                separator: true,
                            },
                            {
                                label: this.translate.instant('Pets'),
                                icon: 'mi mi-pets',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'pets'],
                            },
                        ]
                    },
                    {
                        label: this.translate.instant('Rent'),
                        icon: 'mi mi-currency-exchange',
                        items: [
                            {
                                label: this.translate.instant('Changes'),
                                icon: 'mi mi-change-circle',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'contract-rent-changes'],
                            },
                            {separator: true},
                            {
                                label: this.translate.instant('Increases'),
                                icon: 'mi mi-trending-up',
                                routerLink: [this.currentRoute, 'rentals', 'tenants', 'rent-increases'],
                            },
                        ]
                    },
/*                    {
                        label: this.translate.instant('Keys'),
                        icon: 'mi mi-key',
                        routerLink: [this.currentRoute, 'rentals', 'tenants', 'keys'],
                    },*/
                    {
                        separator: true
                    },
/*                    {
                        label: this.translate.instant('Tenant-Portal'),
                        styleClass: 'menuRentalMoveOuts',
                        icon: 'pi pi-external-link',
                    },*/
                ]
            },
            {
                label: this.translate.instant('Exposes'),
                icon: 'mi mi-filter',
                routerLink: [this.currentRoute, 'rentals', 'exposes', 'list'],
                routerLinkActiveOptions: {exact: true},
            },
            {
                label: this.translate.instant('Listings'),
                icon: 'mi mi-storefront',
                routerLinkActiveOptions: {exact: true},
                items: [
                    {
                        label: this.translate.instant('All Listings'),
                        icon: 'mi mi-list',
                        routerLink: [this.currentRoute, 'rentals', 'exposes', 'listings'],
                    },
                    {
                        separator: true
                    },
                    {
                        label: this.translate.instant('Publications'),
                        icon: 'mi mi-checklist',
                        routerLink: [this.currentRoute, 'rentals', 'exposes', 'listing-publications'],
                    },
                ]
            },
            {
                label: this.translate.instant('Move-Outs'),
                icon: 'mi mi-logout',
                items: [
                    {
                        label: this.translate.instant('Contract Terminations'),
                        icon: 'mi mi-highlight-off',
                        routerLink: [this.currentRoute, 'rentals', 'move-outs', 'contract-terminations'],
                    },
                    {
                        separator: true
                    },
                    {
                        label: '1. ' + this.translate.instant('Termination Confirmations'),
                        items: [
                            {
                                label: 'All Confirmations',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'termination-confirmations'],
                            },
                            {
                              separator: true
                            },
                            {
                                label: '1. New',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'termination-confirmations'],
                                queryParams: {status: 'NEW'},
                            },
                            {
                                label: '2. Waiting',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'termination-confirmations'],
                                queryParams: {status: 'WAITING'},
                            },
                            {
                                separator: true
                            },
                            {
                                label: 'Archive',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'termination-confirmations'],
                                queryParams: {status: 'ARCHIVED'},
                            },
                        ]
                    },
                    {
                        label: '2. ' + this.translate.instant('Viewing Appointments'),
                        disabled: true,
                    },
                    {
                        label: '3. ' + this.translate.instant('Handovers'),
                        items: [
                            {
                                label: 'All Handovers',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'handover-protocols'],
                                queryParams: {type: HandoverProtocolType.MOVE_OUT}
                            },
                            {
                                separator: true
                            },
                            {
                                label: this.translate.instant('Handover Appointments'),
                                icon: 'pi pi-calendar',
                                disabled: true,
                            },
                        ]
                    },
                    {
                        label: '4. ' + this.translate.instant('Deposit Invoices'),
                        items: [
                            {
                                label: 'All Invoices',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'deposit-invoices'],
                            },
                            {
                              separator: true
                            },
                            {
                                label: '1. Drafts',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'deposit-invoices'],
                                queryParams: {status: 'DRAFT'},
                            },
                            {
                                label: '2. Waiting for Delivery',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'deposit-invoices'],
                                queryParams: {status: 'WAITING_FOR_DELIVERY'},
                            },
                            {
                                label: '3. Waiting for Payment',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'deposit-invoices'],
                                queryParams: {status: 'WAITING_FOR_PAYMENT'},
                            },
                            {
                                label: '4. Payment Lawsuits',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'payment-lawsuits?type=deposit'],
                                queryParams: {status: 'waiting-for-payment'},
                            },
                            {
                                separator: true
                            },

                            {
                                label: 'Done',
                                styleClass: 'archive-icon',
                                icon: 'pi pi-download',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'payment-lawsuits?type=deposit'],
                                queryParams: {status: 'waiting-for-payment'},
                            },
                        ]
                    },
                    {separator: true},
                    {
                        label: this.translate.instant('Archive'),
                        styleClass: 'archive-icon',
                        icon: 'pi pi-download',
                        routerLink: [this.currentRoute, 'rentals', 'move-outs', 'contract-terminations'],
                        queryParams: {status: 'archived'},
                    }
                ]
            },
            {
                label: this.translate.instant('Move-Ins'),
                icon: 'mi mi-exit-to-app',
                items: [
                    {
                        label: this.translate.instant('All Requests'),
                        icon: 'mi mi-assignment-ind',
                        routerLink: [this.currentRoute, 'rentals', 'move-ins', 'requests'],
                        routerLinkActiveOptions: {exact: true},
                    },
                    {
                        separator: true,
                    },
                    {
                        label: '1. ' + this.translate.instant('New Requests'),
                        routerLink: [this.currentRoute, 'rentals', 'move-ins', 'requests'],
                        queryParams: {status: RentRequestStatus.NEW_REQUEST},
                        routerLinkActiveOptions: {exact: true},
                    },
                    {
                        label: '2. ' + this.translate.instant('Simple Self-Disclosures'),
                        disabled: true,
                    },
                    {
                        label: '3. ' + this.translate.instant('Viewing Appointments'),
                        disabled: true,
                    },
                    {
                        label: '4. ' + this.translate.instant('Detailed Self-Disclosures'),
                        disabled: true,
                    },
                    {
                        label: '5. ' + this.translate.instant('Solvency Proofs'),
                        disabled: true,
                    },
                    {
                        label: '6. ' + this.translate.instant('Contract Creations'),
                        disabled: true,
                    },
                    {
                        label: '7. ' + this.translate.instant('Handovers'),
                        items: [
                            {
                                label: this.translate.instant('All Handovers'),
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'rentals', 'move-outs', 'handover-protocols'],
                                queryParams: {type: HandoverProtocolType.MOVE_IN}
                            },
                            {
                                separator: true
                            },
                            {
                                label: this.translate.instant('Handover Appointments'),
                                icon: 'pi pi-calendar',
                                disabled: true,
                            },
                            {
                                separator: true,
                            },
                            {
                                label: this.translate.instant('Welcome-Letters'),
                                icon: 'mi mi-local-post-office',
                                disabled: true
                            },
                        ]
                    },
                    {
                        separator: true,
                    },
                    {
                        label: this.translate.instant('Waitlist'),
                        icon: 'mi mi-list',
                        queryParams: {isOnWaitingListCheck: true},
                        routerLink: [this.currentRoute, 'rentals', 'move-ins', 'requests'],
                        routerLinkActiveOptions: {exact: true},
                    },
                    {
                        separator: true,
                    },
                    {
                        label: this.translate.instant('Declines'),
                        icon: 'mi mi-disabled-by-default',
                        items: [
                            {
                                label: 'For Review',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'declines'],
                                queryParams: {status: 'FOR_REVIEW'},
                            },
                            {
                                separator: true,
                            },
                            {
                                label: 'All Declines',
                                routerLink: [this.currentRoute, 'rentals', 'move-ins', 'requests'],
                                queryParams: {status: RentRequestStatus.DECLINE},
                            },
                        ]
                    },
                ]
            },
        ]
    }
}
