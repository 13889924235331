import {Component, OnInit} from "@angular/core";
import {MenuItem, PrimeIcons} from "primeng/api";
import {HeaderService} from "../../header.service";
import {MenuProfileModel} from "../1-module-menu/menu-models/menu-profile.model";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {getCurrentRoute} from "../../../../functions/get-current-route.function";

@Component({
  selector: 'app-header-profile-menu',
  templateUrl: './profile-menu.component.html',
  styleUrls: ['./profile-menu.component.scss']
})
export class ProfileMenuComponent {

  protected readonly PrimeIcons = PrimeIcons;
  menu :MenuProfileModel;
  profileItems: MenuItem[] = [];

  constructor(
      private router:Router,
      private translate: TranslateService,
  ) {
    this.menu = new MenuProfileModel(this.translate, getCurrentRoute(router));
    this.profileItems = this.menu.menuItems;
  }


}