import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import {ApplicationRef, ChangeDetectorRef, Injectable} from "@angular/core";
import {AuthService} from "./auth.service";
import {map, Observable, take} from "rxjs";
import {ThemeService} from "../shared/theme.service";
import {TranslateService} from "@ngx-translate/core";
import {AppLanguageEnum} from "../enums/app-language.enum";

@Injectable({providedIn: 'root'})
export class AuthGuard  {

    constructor(
        private authService: AuthService,
        private themeService: ThemeService,
        private router: Router,
        private translate: TranslateService,
    ) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        router: RouterStateSnapshot
    ): boolean | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        return this.authService.user.pipe(
            take(1),
            map(user => {
                console.log('__CAN_ACTIVATE_AUTH_GUARD__')
                const isAuth = !!user // if user exists then isAuth is true
                if (isAuth && user.enterprises) {
                    console.log('__CAN_ACTIVATE_AUTH_GUARD__IS_AUTH__')
                    console.log(user.language)
                    console.log(user.language.toLowerCase())
                    this.translate.use(user.language.toLowerCase());
                    localStorage.setItem('redirectUrl', router.url);
                    return true;
                } else {
                    localStorage.setItem('redirectUrl', router.url);
                    return this.router.createUrlTree(['/auth']);
                }
            })
        );
    }
}
