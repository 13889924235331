<p-toast position="bottom-center"></p-toast>
<p-confirmDialog [style]="{width: '450px'}" defaultFocus="reject"></p-confirmDialog>

<div *ngIf="loadPage" style="position: absolute; left: 49vw; top: 49vh;">
    <p-progressSpinner styleClass="w-4rem h-4rem" strokeWidth="4" animationDuration=".5s"></p-progressSpinner>
</div>
<div [ngClass]="loadPage === true ? 'page-loading' : 'page-loaded'">
    <app-header *ngIf="!isAuth && !loadPage"></app-header>
    <router-outlet></router-outlet>
</div>

