import {Inject, Injectable} from "@angular/core";
import {DOCUMENT} from "@angular/common";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class ThemeService {

    isDarkMode: boolean;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private http: HttpClient,
    ) {
    }

    setTheme() {
        console.log(this.isDarkMode)
        const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
        themeLink.href = 'vela-blue.css';
        document.documentElement.classList.toggle('dark', true);
        // document.documentElement.classList.toggle('light', !this.isDarkMode);
    }

    switchTheme() {

        console.log(this.isDarkMode + 'TEST')

        this.isDarkMode = (this.isDarkMode === true ? false : true);

        const htmlElement = document.documentElement;
        if(this.isDarkMode) {
            htmlElement.setAttribute('data-bs-theme', 'dark');
        } else {
            htmlElement.setAttribute('data-bs-theme', 'light');
        }

        localStorage.setItem('darkMode', this.isDarkMode.toString());

        const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;

        themeLink.href = this.isDarkMode ? 'vela-blue.css' : 'saga-blue.css';
        document.documentElement.classList.toggle('dark', this.isDarkMode);
        document.documentElement.classList.toggle('light', !this.isDarkMode);

    }


}
