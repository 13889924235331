import {Component} from "@angular/core";
import {PrimeIcons} from "primeng/api";
import {Router} from "@angular/router";
import {getCurrentRoute} from "../../../../functions/get-current-route.function";
import {NavigationService} from "../../../../services/navigation.service";

@Component({
  selector: 'app-header-appointments-button',
  templateUrl: './appointments-button.component.html',
  styleUrls: ['./appointments-button.component.scss']
})
export class AppointmentsButtonComponent {

  protected readonly PrimeIcons = PrimeIcons;
  appointmentBadgeCount: string = '';

  constructor(
      private navigationService:NavigationService,
  ) {
  }

  async navigate(route: string[]) {
    await this.navigationService.navigate(route);
  }


}