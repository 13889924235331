import {Injectable} from "@angular/core";
import {Router} from "@angular/router";


@Injectable({providedIn: 'root'})
export class NavigationService {

    constructor(private router: Router) {
    }


    getCurrentRoute(): string {
        const enterpriseId = this.router.url.split('/')[1];
        const countryId = this.router.url.split('/')[2];
        return ('/' + enterpriseId + '/' + countryId);
    }

    navigate(path: string[], isCurrentRouteIncluded:boolean = true): Promise<boolean> {
        if(isCurrentRouteIncluded) {
            const currentRoute = this.getCurrentRoute();
            path.unshift(currentRoute);
        }
        return this.router.navigate(path);
    }


}