import {EventEmitter} from "@angular/core";
import {TranslateService} from "@ngx-translate/core";
import {MenuItem} from "primeng/api";

export class MenuProfileModel {

    public logout = new EventEmitter();

    public onThemeModeToggle = new EventEmitter();

    menuItems: MenuItem[] = [];


    constructor(
        private translate: TranslateService,
        private currentRoute?: string,
        private darkMode?: boolean,
    ) {

        this.menuItems = [
            {
                label: 'User',
                items: [
                    {label: this.translate.instant('Settings'), routerLink: [this.currentRoute, 'user', 'settings'], icon: 'mi mi-manage-accounts'},
/*                    {label: 'Help & FAQ', routerLink: [this.currentRoute, 'user', 'help'], icon: 'mi mi-quiz'},
                    {label: 'Dark mode', icon: darkMode ? 'mi mi-dark-mode' : 'mi mi-dark-mode', command: () => this.switchTheme()}*/
                ]
            },
            {
                separator: true
            },
            {
                label: 'Organization',
                items: [
                    {label: 'Settings', routerLink: [this.currentRoute, 'master', 'settings'], icon: 'mi mi-settings-applications'},
                    {label: 'Connected Apps', routerLink: [this.currentRoute, 'master', 'settings', 'connected-apps'], icon: 'mi mi-dataset-linked'},
                    {label: 'Billing', routerLink: [this.currentRoute, 'master', 'settings', 'billings'], icon: 'mi mi-credit-score'},
                    {label: 'Team', routerLink: [this.currentRoute, 'master', 'users'], icon: 'mi mi-groups'},
                    {label: 'ToS', routerLink: [this.currentRoute, 'legal', 'termsofservice'], icon: 'mi mi-description'},
                    {label: 'Privacy Policy', routerLink: [this.currentRoute, 'legal', 'privacypolicy'], icon: 'mi mi-lock'},
                    {
                        separator: true
                    },
                    {
                        label: 'Log out',
                        icon: 'mi mi-logout',
                        command: () => this.onLogout()
                    },
                ]
            },
        ]

        this.setDarkMode(Boolean(localStorage.getItem('darkMode')));

    }

    translateMenuProfile() {
        this.menuItems[0].label = this.translate.instant('User');
        this.menuItems[0].items[0].label = this.translate.instant('Settings');
/*        this.menuItems[0].items[1].label = this.translate.instant('Help & FAQ');
        this.menuItems[0].items[2].label = this.translate.instant('Dark mode');*/
        this.menuItems[2].label = this.translate.instant('Organization');
        this.menuItems[2].items[0].label = this.translate.instant('Settings');
        this.menuItems[2].items[1].label = this.translate.instant('Integrations');
        this.menuItems[2].items[2].label = this.translate.instant('Billing');
        this.menuItems[2].items[3].label = this.translate.instant('Users');
        this.menuItems[2].items[4].label = this.translate.instant('GTCs');
        this.menuItems[2].items[5].label = this.translate.instant('Privacy');
        this.menuItems[2].items[6].label = this.translate.instant('Log out');
    }


    setDarkMode(darkMode?:boolean) {
/*        if(darkMode) {
            this.menuItems[0].items[2].label = this.translate.instant('Light mode');
            this.menuItems[0].items[2].icon = 'pi pi-sun';
        } else {
            this.menuItems[0].items[2].label = this.translate.instant('Dark mode');
            this.menuItems[0].items[2].icon = 'mi mi-dark-mode';
        }*/
    }

    onLogout() {
        this.logout.emit();
    }

    switchTheme() {
/*        const icon = this.menuItems[0].items[2].icon;
        if(icon === 'pi pi-sun') {
            this.menuItems[0].items[2].label = this.translate.instant('Dark mode');
            this.menuItems[0].items[2].icon = 'pi pi-moon';
            this.onThemeModeToggle.emit(true)
        } else {
            this.menuItems[0].items[2].label = this.translate.instant('Light mode');
            this.menuItems[0].items[2].icon = 'mi mi-dark-mode';
            this.onThemeModeToggle.emit(false)
        }*/
    }

}
