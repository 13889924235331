
import {NgModule, isDevMode} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';

import {MenubarModule} from 'primeng/menubar';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';

import {AuthComponent} from './system/auth/auth.component';
import {LoadingSpinnerComponent} from "./system/shared/loading-spinner/loading-spinner.component";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {FormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {ProgressSpinnerModule} from "primeng/progressspinner";
import {AuthInterceptorService} from "./system/auth/auth-interceptor.service";
import {MenuModule} from "primeng/menu";
import {ToggleButtonModule} from "primeng/togglebutton";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {TabViewModule} from "primeng/tabview";
import {ConfirmationService, MessageService} from "primeng/api";
import {DropdownModule} from "primeng/dropdown";
import {MasterModule} from "./0-settings/1-enterprise-settings/master.module";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {DialogModule} from "primeng/dialog";
import {TableModule} from "primeng/table";
import { ErrorComponent } from './system/error/error.component';
import {ChooseEnterpriseComponent} from "./system/auth/choose-enterprise/choose-enterprise.component";
import {SpinnerModule} from "primeng/spinner";
import {FileUploadModule} from "primeng/fileupload";
import {environment} from "../environments/environment";
import {BadgeModule} from "primeng/badge";
import {CmdClickDirective} from "./system/directives/cmd-click.directive";

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {RippleModule} from "primeng/ripple";
import {ToastModule} from "primeng/toast";
import {ConfirmDialogModule} from "primeng/confirmdialog";
import {TabMenuModule} from "primeng/tabmenu";
import {TieredMenuModule} from "primeng/tieredmenu";
import {HeaderModule} from "./system/components/header/header.module";
import {AppLanguageEnum} from "./system/enums/app-language.enum";
import {StaticTranslateLoader} from "../assets/i18n/static-translate-loader";
registerLocaleData(localeDe);

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/assets/i18n/', '.json' );
}

@NgModule({
    declarations: [
        AppComponent,
        AuthComponent,
        LoadingSpinnerComponent,
        ErrorComponent,
        ChooseEnterpriseComponent,
        CmdClickDirective,
    ],
    imports: [
        HeaderModule,
        BrowserModule,
        AppRoutingModule,
        MenubarModule,
        InputTextModule,
        ButtonModule,
        NgbModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            defaultLanguage: AppLanguageEnum.GB.toLowerCase(),
            loader: {
                provide: TranslateLoader,
                useClass: StaticTranslateLoader
            }
        }),
        ProgressSpinnerModule,
        MenuModule,
        ToggleButtonModule,
        BrowserAnimationsModule,
        ButtonModule,
        TabViewModule,
        DropdownModule,
        MasterModule,
        DialogModule,
        TableModule,
        SpinnerModule,
        FileUploadModule,
        BadgeModule,
        RippleModule,
        ToastModule,
        ConfirmDialogModule,
        TabMenuModule,
        TieredMenuModule,
    ],
    providers: [
        {provide: localeDe, useValue: AppLanguageEnum.GB.toLowerCase()},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
        MessageService,
        ConfirmationService,
    ],
    exports: [
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

