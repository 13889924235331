import {TranslateService} from "@ngx-translate/core";

export class MenuPropertiesModel {

    menuItems: any;

    constructor(

        private translate: TranslateService,
        private currentRoute?: string,


    ) {


        this.menuItems = [
            {
                label:  this.translate.instant('Plots'),
                icon: 'mi mi-map',
                items: [
                    {
                        label:  this.translate.instant('All Plots'),
                        icon: 'mi mi-list',
                        routerLink: ([this.currentRoute, 'properties', 'plots']),
                        queryParams: {},
                        queryParamsHandling: 'merge',
                        routerLinkActiveOptions: {exact: false}
                    },
/*                    {
                        label: 'Land Register Extracts',
                        icon: 'mi mi-text-snippet',
                        styleClass: 'site-plan-icon',
                        items: [
                            {
                                label: 'Restrictions',
                                icon: 'mi mi-lock',
                                styleClass: 'menuPropertiesLandRegisterRestrictions',
                                routerLink: [this.currentRoute, 'properties', 'land-register', 'restrictions'],
                            },
                            {
                                label: 'Mortgages',
                                icon: 'mi mi-sell',
                                styleClass: 'menuPropertiesLandRegisterMortgages',
                                routerLink: [this.currentRoute, 'properties', 'land-register', 'mortgages'],
                            },
                        ]
                    },*/
                ]
            },
            {
                label:  this.translate.instant('Buildings'),
                icon: 'mi mi-business',
                items: [
                    {
                        label: 'All Buildings',
                        icon: 'mi mi-list',
                        routerLink: [this.currentRoute, 'properties', 'buildings'],
                        routerLinkActiveOptions: {exact: false}
                    },
                    {
                        separator: true,
                    },
                    {
                        label: 'Building Complexes',
                        icon: 'mi mi-location-city',
                        styleClass: 'menuPropertiesBuildingComplexes',
                        routerLink: [this.currentRoute, 'properties', 'building-complexes'],
                    },
                    {
                        separator: true,
                    },
                    {
                        label: 'Energy Certificates',
                        icon: 'mi mi-energy-savings-leaf',
                        routerLink: [this.currentRoute, 'properties', 'energy-certificates'],
                    },
/*                    {
                        label: 'Building Permits',
                        icon: 'mi mi-assignment-turned-in',
                        routerLink: [this.currentRoute, 'properties', 'building-permits'],
                    },*/
                ]
            },
            {
                label:  this.translate.instant('Units'),
                icon: 'mi mi-home-work',
                styleClass: 'menuPropertiesUnits',
                items: [
                    {
                        label: 'All Units',
                        icon: 'mi mi-list',
                        routerLink: [this.currentRoute, 'properties', 'units'],
                        routerLinkActiveOptions: {exact: false}
                    },
                    {
                        separator: true,
                    },
                    {
                        label: 'Shared Units',
                        icon: 'mi mi-bedroom-parent',
                        routerLink: [this.currentRoute, 'properties', 'shared-units'],
                        routerLinkActiveOptions: {exact: false}
                    },
                    {
                        separator: true,
                    },
                    {
                        label: 'Parking Spaces',
                        icon: 'mi mi-local-parking',
                        routerLink: [this.currentRoute, 'properties', 'parking-spaces'],
                        routerLinkActiveOptions: {exact: false}
                    },
                ]
            },
            {
                label: this.translate.instant('Site Plans'),
                icon: 'mi mi-grid-on',
                routerLink: [this.currentRoute, 'properties', 'site-plans'],
            },
            {
                label: this.translate.instant('Floor Plans'),
                icon: 'mi mi-square-foot',
                routerLink: [this.currentRoute, 'properties', 'floor-plans'],
                routerLinkActiveOptions: {exact: false}
            },
            {
                label:  this.translate.instant('Rooms'),
                icon: 'mi mi-meeting-room',
                routerLink: [this.currentRoute, 'properties', 'rooms'],
                routerLinkActiveOptions: {exact: true},
            },
            {
                label: 'Equipment',
                icon: 'pi pi-objects-column',
                routerLinkActiveOptions: {exact: false},
                items: [
/*                    {
                        label: 'Equipment',
                        icon: 'mi mi-list',
                        routerLink: [this.currentRoute, 'properties', 'equipments'],
                        routerLinkActiveOptions: {exact: false},
                    },
                    {
                        separator: true
                    },*/
                    {
                        label:  this.translate.instant('Meters'),
                        icon: 'mi mi-gas-meter',
                        routerLinkActiveOptions: {exact: false},
                        items: [
                            {
                                label: 'All Meters',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'properties', 'meters'],
                                routerLinkActiveOptions: {exact: false}
                            },
                            {
                                separator: true,
                            },
                            {
                                label: 'Meter Devices',
                                icon: 'mi mi-electric-meter',
                                routerLink: [this.currentRoute, 'properties', 'meter-devices'],
                                routerLinkActiveOptions: {exact: false}
                            },
                            {
                                label: 'Device-Readings',
                                icon: 'mi mi-123',
                                routerLink: [this.currentRoute, 'properties', 'device-readings'],
                                routerLinkActiveOptions: {exact: false}
                            },
                        ]
                    },
                    /*{
                      separator: true
                    },
                    {
                        label: 'Locking-Systems',
                        icon: 'mi mi-private-connectivity',
                        routerLink: [this.currentRoute, 'properties', 'locking-systems'],
                        items: [
                            {
                                label: 'All Locking-Systems',
                                icon: 'mi mi-list',
                                routerLink: [this.currentRoute, 'properties', 'locking-systems'],
                            },
                            {
                                separator: true
                            },
                            {
                                label: 'Locks',
                                icon: 'mi mi-lock',
                                routerLink: [this.currentRoute, 'properties', 'locks'],
                            },
                            {
                                label: 'Keys',
                                icon: 'mi mi-key',
                                routerLink: [this.currentRoute, 'properties', 'keys'],
                            },
                            {
                                separator: true
                            },
                            {
                                label: 'Key-Safes',
                                icon: 'mi mi-enhanced-encryption',
                                routerLink: [this.currentRoute, 'properties', 'key-safes'],
                            },
                        ]
                    },
                    {
                      separator: true
                    },
                    {
                        label: 'Warranties',
                        icon: 'mi mi-task',
                        routerLink: [this.currentRoute, 'properties', 'equipment-warranties'],
                        routerLinkActiveOptions: {exact: false}
                    },*/
                ]
            },
        ]
    }

}
